/**
 * Map AIS Panels
 */
export const SETTINGS_PANEL_ID = 'settings';
export const VESSEL_PANEL_ID = 'vessel';
export const MOVEMENTS_PANEL_ID = 'movements';

/**
 * Map Periods Panels
 */
export const INSTANT_PAST_PANEL_ID = 'past';
export const INSTANT_FUTURE_PANEL_ID = 'future';
export const REQUESTEDS_PANEL_ID = 'requesteds';
export const PLANNING_PANEL_ID = 'planning';
export const INSTANT_PRECONFIGURED_PANEL_ID = 'preconfigured';
export const TEMPORAL_PANEL_ID = 'temporal';
