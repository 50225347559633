import moment from 'moment';

/// //////////////// MOMENTJS TIME SUPPORT
export default {
	getLocalDateNowMillis: () => {
		return moment();
	},
	getLocalDateNow: (format) => {
		var f = 'YYYY-MM-DD';
		if (format) {
			f = format;
		}
		return moment().format(f);
	},
	formatDate: (date, format) => {
		return moment(date).format(format);
	},
	getLocalTimeNow: (format) => {
		var f = 'HH:mm:ss';
		if (format) {
			f = format;
		}
		return moment().format(f);
	},
	getLocalDate: (date) => {
		if (date) {
			return moment(date).format();
		}
		return date;
	},
	getLocalFormattedDateFromMillis: (millis, isoFormat) => {
		return moment(millis).format(isoFormat);
	},
	getLocalFormattedDate: (date, isoFormat) => {
		if (date) {
			return moment(date).format(isoFormat);
		}
		return date;
	},
	isLocalDateValid: (date) => {
		return moment(date).isValid();
	},
	isLocalDateBefore: (date, date2) => {
		var d2 = moment(date2);
		return moment(date).isBefore(d2);
	},
	isLocalDateSameOrBefore: (date, date2) => {
		var d2 = moment(date2);
		return moment(date).isSameOrBefore(d2);
	},
	isLocalDateAfter: (date, date2) => {
		var d2 = moment(date2);
		return moment(date).isAfter(d2);
	},
	isLocalDateSameOrAfter: (date, date2) => {
		var d2 = moment(date2);
		return moment(date).isSameOrAfter(d2);
	},
	isLocalDateEqual: (date, date2) => {
		var d2 = moment(date2);
		return moment(date).isSame(d2);
	},
	getUTCDate: (date) => {
		return moment.utc(date).format();
	},
	getUTCOffset: () => {
		return moment().utcOffset();
	},
	getUTCDateNow: () => {
		return moment.utc().format();
	},
	addDaysToLocalDate(date, numberOfDays) {
		return moment(date).add(numberOfDays, 'days').utc().format();
	},
	getMomentFromDate(date) {
		return moment(date);
	}
};
