<template>
	<!-- DESKTOP -->
	<div v-if="!isMobile">
		<div v-if="toplabel" class="ml-1 mr-1">
			<v-layout>
				<v-flex xs12>
					<label v-if="getLabel === '$nbsp;'">&nbsp;</label>
					<label v-else :class="getLabelRequiredClass">{{ getLabel }}</label>
					<pui-form-tooltip v-bind="{ tooltipDescription, tooltipIcon }" v-if="showTooltip" ref="tooltip"></pui-form-tooltip>
				</v-flex>
			</v-layout>
			<v-layout>
				<v-flex xs12>
					<v-layout>
						<v-flex :xs7="showTime" :xs12="!showTime">
							<!-- use attach on v-menu but it does not working at the moment... -->
							<!-- :attach="`#${attach !== null ? attach : defaultId}`" -->
							<v-menu
								:close-on-content-click="false"
								v-model="dateMenuModel"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
								:disabled="disabled"
								:key="refresher"
								><template v-slot:activator="{ on, attrs }">
									<v-text-field
										:ref="`dateField${attach !== null ? attach : defaultId}`"
										:id="`${attach !== null ? attach : defaultId}`"
										v-on="on"
										v-bind="attrs"
										v-model="internalDateFieldModel"
										:class="getEditedDateClass"
										:rules="getDateRules"
										append-icon="far fa-calendar-day"
										clearable
										solo
										outlined
										flat
										:readonly="disableTextInput"
										:disabled="disabled"
										:mask="getDateMask"
										:error="internalDateError"
										:error-messages="internalDateErrorMessage"
										:placeholder="getDatePlaceholder"
										@input="updateDateFieldModel"
										@click:append="dateMenuModel = true"
										@click:clear="internalDatePickerModel = null"
										@focus="allValidations()"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="internalDatePickerModel"
									:first-day-of-week="1"
									:locale="userLocaleCode"
									v-bind="allProps"
									:disabled="disabled"
									:min="minDateValue"
									:max="maxDateValue"
									color="var(--primarycolor)"
								></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex :xs5="showTime" :xs0="!showTime">
							<v-menu
								v-if="showTime"
								:close-on-content-click="false"
								v-model="timeMenuModel"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="150px"
								:disabled="disabled"
								:key="refresher"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-on="on"
										v-bind="attrs"
										:ref="`timeField${attach !== null ? attach : defaultId}`"
										v-model="internalTimeFieldModel"
										:class="getEditedTimeClass"
										:rules="getTimeRules"
										append-icon="fa-clock"
										clearable
										solo
										outlined
										flat
										:readonly="disableTextInput"
										:disabled="disabled"
										class="ml-1"
										:mask="getTimeMask"
										:error="internalTimeError"
										:error-messages="internalTimeErrorMessage"
										:placeholder="getTimePlaceholder"
										@input="updateTimeFieldModel"
										@click:append="timeMenuModel = true"
										@click:clear="internalTimePickerModel = null"
										@focus="allValidations()"
									></v-text-field>
								</template>
								<v-time-picker
									v-if="timeMenuModel"
									v-model="internalTimePickerModel"
									v-bind="allProps"
									:disabled="disabled"
									:min="minTimeValueForPicker"
									:max="maxTimeValueForPicker"
									format="24hr"
									:use-seconds="timesecs"
									color="var(--primarycolor)"
									@click:hour="timeMenuModel = true"
									@click:minute="timeMenuModel = timesecs"
									@click:second="timeMenuModel = false"
								></v-time-picker>
							</v-menu>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</div>
		<div v-else class="ml-1 mr-1">
			<v-layout>
				<v-flex :class="labelColumnStyles ? labelColumnStyles : 'xs12 sm6 md4 xl3'">
					<label :class="getLabelRequiredClass">{{ getLabel }}</label>
					<pui-form-tooltip v-bind="{ tooltipDescription, tooltipIcon }" v-if="showTooltip" ref="tooltip"></pui-form-tooltip>
				</v-flex>
				<v-flex :class="valueColumnStyles ? valueColumnStyles : 'xs12 sm6 md8 xl9'">
					<v-layout>
						<v-flex :xs7="showTime" :xs12="!showTime">
							<v-menu
								:close-on-content-click="false"
								v-model="dateMenuModel"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="290px"
								:disabled="disabled"
								:key="refresher"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										:ref="`dateField${attach !== null ? attach : defaultId}`"
										:id="`${attach !== null ? attach : defaultId}`"
										v-on="on"
										v-bind="attrs"
										v-model="internalDateFieldModel"
										:class="getEditedDateClass"
										class="ml-2"
										:rules="getDateRules"
										append-icon="far fa-calendar-day"
										clearable
										solo
										outlined
										flat
										:readonly="disableTextInput"
										:disabled="disabled"
										:mask="getDateMask"
										:error="internalDateError"
										:error-messages="internalDateErrorMessage"
										:placeholder="getDatePlaceholder"
										@input="updateDateFieldModel"
										@click:append="dateMenuModel = true"
										@click:clear="internalDatePickerModel = null"
										@focus="allValidations()"
									></v-text-field>
								</template>
								<v-date-picker
									v-model="internalDatePickerModel"
									:first-day-of-week="1"
									:locale="userLocaleCode"
									v-bind="allProps"
									:disabled="disabled"
									:min="minDateValue"
									:max="maxDateValue"
									color="var(--primarycolor)"
								></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex :xs5="showTime" :xs0="!showTime">
							<v-menu
								v-if="showTime"
								:close-on-content-click="false"
								v-model="timeMenuModel"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="150px"
								:disabled="disabled"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-on="on"
										v-bind="attrs"
										:ref="`timeField${attach !== null ? attach : defaultId}`"
										v-model="internalTimeFieldModel"
										:class="getEditedTimeClass"
										class="ml-1"
										:rules="getTimeRules"
										append-icon="fa-clock"
										clearable
										solo
										outlined
										flat
										:readonly="disableTextInput"
										:disabled="disabled"
										:mask="getTimeMask"
										:error="internalTimeError"
										:error-messages="internalTimeErrorMessage"
										:placeholder="getTimePlaceholder"
										@input="updateTimeFieldModel"
										@click:append="timeMenuModel = true"
										@click:clear="internalTimePickerModel = null"
										@focus="allValidations()"
									></v-text-field>
								</template>
								<v-time-picker
									v-if="timeMenuModel"
									v-model="internalTimePickerModel"
									v-bind="allProps"
									:disabled="disabled"
									:min="minTimeValueForPicker"
									:max="maxTimeValueForPicker"
									format="24hr"
									:use-seconds="timesecs"
									color="var(--primarycolor)"
									@click:hour="timeMenuModel = true"
									@click:minute="timeMenuModel = timesecs"
									@click:second="timeMenuModel = false"
								></v-time-picker>
							</v-menu>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</div>
	</div>
	<!-- MOBILE -->
	<div v-else>
		<v-layout>
			<v-flex xs12>
				<v-layout>
					<v-flex :xs7="showTime" :xs12="!showTime">
						<v-menu
							:close-on-content-click="false"
							v-model="dateMenuModel"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="290px"
							:disabled="disabled"
							:key="refresher"
							><template v-slot:activator="{ on, attrs }">
								<v-text-field
									:ref="`dateField${attach !== null ? attach : defaultId}`"
									:id="`${attach !== null ? attach : defaultId}`"
									v-on="on"
									v-bind="attrs"
									v-model="internalDateFieldModel"
									append-icon="far fa-calendar-day"
									:class="getMobileClass"
									:rules="getDateRules"
									class="v-text-field--mobile"
									:disabled="disabled"
									:label="getLabel"
									:mask="getDateMask"
									:error="internalDateError"
									:error-messages="internalDateErrorMessage"
									:placeholder="getDatePlaceholder"
									@input="updateDateFieldModel"
									@click:append="dateMenuModel = true"
									@click:clear="internalDatePickerModel = null"
									@focus="allValidations()"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="internalDatePickerModel"
								:first-day-of-week="1"
								:locale="userLocaleCode"
								v-bind="allProps"
								:disabled="disabled"
								:min="minDateValue"
								:max="maxDateValue"
								color="var(--primarycolor)"
							></v-date-picker>
						</v-menu>
					</v-flex>
					<v-flex :xs5="showTime" :xs0="!showTime">
						<v-menu
							v-if="showTime"
							:close-on-content-click="false"
							v-model="timeMenuModel"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="150px"
							:disabled="disabled"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-on="on"
									v-bind="attrs"
									:ref="`timeField${attach !== null ? attach : defaultId}`"
									v-model="internalTimeFieldModel"
									:class="getEditedTimeClass"
									class="ml-1"
									:rules="getTimeRules"
									append-icon="fa-clock"
									:disabled="disabled"
									:mask="getTimeMask"
									:error="internalTimeError"
									:error-messages="internalTimeErrorMessage"
									:placeholder="getTimePlaceholder"
									@input="updateTimeFieldModel"
									@click:append="timeMenuModel = true"
									@click:clear="internalTimePickerModel = null"
									@focus="allValidations()"
								></v-text-field>
							</template>
							<v-time-picker
								v-if="timeMenuModel"
								v-model="internalTimePickerModel"
								v-bind="allProps"
								:disabled="disabled"
								:min="minTimeValueForPicker"
								:max="maxTimeValueForPicker"
								format="24hr"
								:use-seconds="timesecs"
								color="var(--primarycolor)"
								@click:hour="timeMenuModel = true"
								@click:minute="timeMenuModel = timesecs"
								@click:second="timeMenuModel = false"
							></v-time-picker>
						</v-menu>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import moment from 'moment';
import PuiFormComponentMixin from './PuiFormComponentMixin';
import dateTimeUtils from './dateTimeUtils';

export default {
	name: 'PuiDateField',
	mixins: [PuiFormComponentMixin],
	props: {
		disableTextInput: {
			type: Boolean,
			default: false,
			required: false
		},
		value: {
			type: [Number, String],
			required: false
		},
		today: {
			type: Boolean,
			default: false,
			required: false
		},
		time: {
			type: Boolean,
			default: false,
			required: false
		},
		tooltip: {
			type: Boolean,
			required: false,
			default: false
		},
		tooltipDescription: {
			type: String,
			required: false
		},
		tooltipIcon: {
			type: String,
			required: false
		},
		timesecs: {
			type: Boolean,
			default: false,
			required: false
		},
		rules: {
			type: Array,
			default: () => {
				return [];
			}
		},
		isoDateFormat: {
			type: String,
			required: false
		},
		min: {
			type: [Number, Date, String],
			required: false
		},
		max: {
			type: [Number, Date, String],
			required: false
		},
		labelColumnStyles: {
			type: String,
			required: false
		},
		valueColumnStyles: {
			type: String,
			required: false
		},
		attach: {
			type: String,
			default: null,
			required: false
		}
	},
	data: () => ({
		debug: false,
		defaultId: `id_${Math.random().toString().replace('.', '')}`,
		firstLoad: true,
		internalUTCModel: null,
		internalDatePickerModel: null,
		internalDateFieldModel: null,
		internalTimePickerModel: null,
		internalTimeFieldModel: null,
		initialDateValue: null,
		initialTimeValue: null,
		dateMenuModel: false,
		timeMenuModel: false,
		internalDatePickerFormat: 'YYYY-MM-DD',
		internalDateError: false,
		internalTimeError: false,
		internalDateErrorMessage: '',
		internalTimeErrorMessage: '',
		refresher: 0
	}),
	computed: {
		minDateValue() {
			return this.min ? moment(this.min).format(this.internalDatePickerFormat) : null;
		},
		maxDateValue() {
			return this.max ? moment(this.max).format(this.internalDatePickerFormat) : null;
		},
		minTimeValueForPicker() {
			return this.min && this.minDateSameDay ? moment(this.min).format(this.ISOtimeFormat) : null;
		},
		maxTimeValueForPicker() {
			return this.max && this.maxDateSameDay ? moment(this.max).format(this.ISOtimeFormat) : null;
		},
		minTimeValueForValidation() {
			return this.min ? moment(this.min).format(this.ISOtimeFormat) : null;
		},
		maxTimeValueForValidation() {
			return this.max ? moment(this.max).format(this.ISOtimeFormat) : null;
		},
		minDateSameDay() {
			return this.min ? this.internalDatePickerModel == this.minDateValue : null;
		},
		maxDateSameDay() {
			return this.max ? this.internalDatePickerModel == this.maxDateValue : null;
		},
		minDateValidation() {
			this.debug && console.log('minDateValidation ' + this.label, moment(this.internalDatePickerModel));
			this.debug && console.log('minDateValidation ' + this.label, moment(this.minDateValue));
			return this.min ? this.minDateSameDay || moment(this.internalDatePickerModel).isSame(moment(this.minDateValue)) || moment(this.internalDatePickerModel).isAfter(moment(this.minDateValue)) : null;
		},
		maxDateValidation() {
			this.debug && console.log('maxDateValidation ' + this.label, moment(this.internalDatePickerModel));
			this.debug && console.log('maxDateValidation ' + this.label, moment(this.maxDateValue));
			return this.max ? this.maxDateSameDay || moment(this.internalDatePickerModel).isSame(moment(this.maxDateValue)) || moment(this.internalDatePickerModel).isBefore(moment(this.maxDateValue)) : null;
		},
		minTimeValidation() {
			let prefix = this.internalDatePickerModel ? this.internalDatePickerModel + 'T' : Date() + 'T';
			this.debug && console.log('minTimeValidation ' + this.label, moment(prefix + this.internalTimePickerModel));
			this.debug && console.log('minTimeValidation ' + this.label, moment(this.minDateValue + 'T' + this.minTimeValueForValidation));
			return this.min ? moment(prefix + this.internalTimePickerModel).isSame(moment(this.minDateValue + 'T' + this.minTimeValueForValidation)) || moment(prefix + this.internalTimePickerModel).isAfter(moment(this.minDateValue + 'T' + this.minTimeValueForValidation)) : null;
		},
		maxTimeValidation() {
			let prefix = this.internalDatePickerModel ? this.internalDatePickerModel + 'T' : Date() + 'T';
			this.debug && console.log('maxTimeValidation ' + this.label, moment(prefix + this.internalTimePickerModel));
			this.debug && console.log('maxTimeValidation ' + this.label, moment(this.maxDateValue + 'T' + this.maxTimeValueForValidation));
			return this.max ? moment(prefix + this.internalTimePickerModel).isSame(moment(this.maxDateValue + 'T' + this.maxTimeValueForValidation)) || moment(prefix + this.internalTimePickerModel).isBefore(moment(this.maxDateValue + 'T' + this.maxTimeValueForValidation)) : null;
		},
		ISOdateFormat() {
			return this.isoDateFormat || (this.$store && this.$store.getters && this.$store.getters.dateFormat) || 'DD/MM/YYYY';
		},
		ISOtimeFormat() {
			return this.timesecs ? 'HH:mm:ss' : 'HH:mm'; // (this.$store && this.$store.getters && this.$store.getters.timeFormat)
		},
		getDateRules() {
			const rules = [...this.rules];
			if (this.required) {
				var func = (value) => !!value || this.requiredMessage;
				rules.push(func);
			}
			var func2 = () => !this.internalDateError || this.internalDateErrorMessage;
			rules.push(func2);
			return rules;
		},
		getTimeRules() {
			const rules = [...this.rules];
			if (this.required) {
				var func = (value) => !!value || this.requiredMessage;
				rules.push(func);
			}
			var func2 = () => !this.internalTimeError || this.internalTimeErrorMessage;
			rules.push(func2);
			return rules;
		},
		showTime() {
			return this.time || this.timesecs;
		},
		allowedTimeLength() {
			return this.timesecs ? 8 : 5;
		},
		getDateMask() {
			// const defaultMask = '##/##/####',

			let mask = this.ISOdateFormat.replace(/Y/g, '#');
			mask = mask.replace(/M/g, '#');
			mask = mask.replace(/D/g, '#');

			return mask;
		},
		getTimeMask() {
			if (this.time) {
				return '##:##';
			} else if (this.timesecs) {
				return '##:##:##';
			}
			return '##:##:##';
		},
		getMobileClass() {
			return { 'v-text-field--edited': this.isEdited, 'v-text-field--required': this.required };
		},
		getLabelRequiredClass() {
			return { 'v-label--required': this.required };
		},
		getEditedDateClass() {
			return { 'v-text-field--edited': this.isEditedDate };
		},
		getEditedTimeClass() {
			return { 'v-text-field--edited': this.isEditedTime };
		},
		isEditedDate() {
			if (this.noeditable || this.disabled || this.readonly) {
				return false;
			} else {
				const newDate = this.internalDateFieldModel;
				if (this.initialDateValue !== newDate) {
					return true;
				}
				return false;
			}
		},
		isEditedTime() {
			if (this.noeditable || this.disabled || this.readonly) {
				return false;
			} else {
				const newTime = this.internalTimeFieldModel;
				if (this.initialTimeValue !== newTime) {
					return true;
				}
				return false;
			}
		},
		getDatePlaceholder() {
			return this.ISOdateFormat;
		},
		getTimePlaceholder() {
			return this.time ? this.ISOtimeFormat : '';
		},
		showTooltip() {
			if (this.getLabel === '' || this.getLabel === null) {
				return false;
			}
			return this.tooltipDescription || this.tooltip;
		}
	},
	watch: {
		value(val) {
			this.debug && console.log('watch value', val);

			if (this.firstLoad) {
				this.initializeModel(val);
				this.firstLoad = false;
			} else {
				this.setModel(val);
			}
		},
		internalDatePickerModel(val) {
			this.debug && console.log('watch internalDatePickerModel', val);

			if (val != null) {
				let formattedDate = dateTimeUtils.getLocalFormattedDate(val, this.ISOdateFormat);
				if (formattedDate != this.internalDateFieldModel) {
					this.updateDateFieldModel(formattedDate);
				}
			}
		},
		internalTimePickerModel(val) {
			this.debug && console.log('watch internalTimePickerModel', val);

			if (val != this.internalTimeFieldModel) {
				this.updateTimeFieldModel(val);
			}
		},
		internalDateErrorMessage() {
			this.$refs[`dateField${this.attach !== null ? this.attach : this.defaultId}`].validate();
		},
		internalTimeErrorMessage() {
			this.showTime && this.$refs[`timeField${this.attach !== null ? this.attach : this.defaultId}`].validate();
		},
		max() {
			this.allValidations();
			this.setTooltip();
		},
		min() {
			this.allValidations();
			this.setTooltip();
		}
	},
	created() {
		this.setInitialValues();
		this.initializeModel(this.value);
	},
	mounted() {
		this.setTooltip();
	},
	methods: {
		initializeModel(value) {
			if (value !== null && value !== undefined) {
				this.setModel(value);
			} else if (this.today) {
				let todayValue = dateTimeUtils.getLocalDateNow() + 'T' + dateTimeUtils.getLocalTimeNow();
				this.setModel(todayValue);
			}
		},
		setInitialValues() {
			this.firstLoad = false;
			this.initialValue = this.value;
			this.initialDateValue = this.internalDateFieldModel;
			this.initialTimeValue = this.internalTimeFieldModel;
		},
		setModel(val) {
			this.debug && console.log('setModel', val);

			if (val) {
				const newDateFieldModel = moment(val).format(this.ISOdateFormat);
				const newDatePickerModel = moment(val).format(this.internalDatePickerFormat);
				const newTimeModel = moment(val).format(this.ISOtimeFormat);

				this.debug && console.log('newDatePickerModel', newDatePickerModel);
				this.debug && console.log('newDateFieldModel', newDateFieldModel);
				this.debug && console.log('newTimeModel', newTimeModel);

				this.internalDatePickerModel = newDatePickerModel;
				this.internalDateFieldModel = newDateFieldModel;
				this.internalTimePickerModel = newTimeModel;
				this.internalTimeFieldModel = newTimeModel;

				this.allValidations();
				this.internalUTCModel = val;
			} else {
				this.internalDatePickerModel = null;
				this.internalDateFieldModel = null;
				this.internalTimePickerModel = null;
				this.internalTimeFieldModel = null;
				this.internalUTCModel = null;
			}
			
			
		},
		updateDateFieldModel(val) {
			this.debug && console.log('updateDateFieldModel', val);
			this.dateMenuModel = false;

			if (val) {
				if (val.length == 10) {
					this.internalDateFieldModel = val;
					this.internalDatePickerModel = dateTimeUtils.getLocalFormattedDate(
						moment(val, this.ISOdateFormat),
						this.internalDatePickerFormat
					);
					this.updateInternalUTCModel(this.internalDateFieldModel, this.internalTimeFieldModel);
				}

				if (val.length > 10) {
					this.internalDateFieldModel = val.substring(0, 10);
					this.updateInternalUTCModel(this.internalDateFieldModel, this.internalTimeFieldModel);
					this.refresher++;
				}
			} else {
				this.internalDateFieldModel = null;
				this.internalDatePickerModel = null;
				this.updateInternalUTCModel(null, this.internalTimeFieldModel);
			}

			this.clearDateErrorMessages();
			this.validateMinMaxDate(dateTimeUtils.getLocalFormattedDate(moment(this.internalDateFieldModel, this.ISOdateFormat)));
			this.validateDate(this.internalDateFieldModel);
			this.validateRequiredDate(this.internalDateFieldModel);
		},
		updateTimeFieldModel(val) {
			this.debug && console.log('updateTimeFieldModel ', val);
			//this.timeMenuModel = false;

			if (val) {
				if (val.length == 2 && val[1] == ':') {
					val = '0' + val[0] + ':';
					this.internalTimeFieldModel = val;
				}

				if (val.length == 2 && parseInt(val) > 24) {
					val = 23 + ':';
					this.internalTimeFieldModel = val;
				}

				if (val.length == 2) {
					val = val + ':';
					this.internalTimeFieldModel = val;
				}

				if (val.length == 5 && parseInt(val.substring(3, 5)) > 59) {
					val = val.substring(0, 2) + ':59';
					if (this.timesecs) {
						val = val + ':';
					}
					this.internalTimeFieldModel = val;
				}

				if (this.timesecs && val.length == 5) {
					val = val + ':';
					this.internalTimeFieldModel = val;
				}

				if (val.length == 8 && parseInt(val.substring(6, 8)) > 59) {
					val = val.substring(0, 5) + ':59';
					this.internalTimeFieldModel = val;
				}

				if (val.length == this.allowedTimeLength) {
					this.internalTimeFieldModel = val;
					this.internalTimePickerModel = val;
					this.updateInternalUTCModel(this.internalDateFieldModel, val);
				}

				if (val.length > this.allowedTimeLength) {
					this.internalTimeFieldModel = val.substring(0, this.allowedTimeLength);
					this.updateInternalUTCModel(this.internalDateFieldModel, this.internalTimeFieldModel);
					this.refresher++;
				}
			} else {
				this.internalTimeFieldModel = null;
				this.internalTimePickerModel = null;
				this.updateInternalUTCModel(this.internalDateFieldModel, null);
			}

			this.clearTimeErrorMessages();
			this.validateMinMaxTime(this.internalDateFieldModel + ' ' + this.internalTimeFieldModel);
			this.validateTime(this.internalTimeFieldModel);
			this.validateRequiredTime(this.internalTimeFieldModel);
		},
		updateInternalUTCModel(date, time) {
			this.debug && console.log('updateInternalUTCModel date', date);
			this.debug && console.log('updateInternalUTCModel time', time);

			if (date == null) {
				this.internalUTCModel = null;
				this.$emit('input', null);
				return;
			}

			if (time == null || time == undefined) {
				time = '00:00:00';
			}

			let localStringDate = date + ' ' + time;
			let internalUTCModel = moment(localStringDate, this.ISOdateFormat + this.ISOtimeFormat).utc().format();

			this.debug && console.log('localStringDate', localStringDate);
			this.debug && console.log('internalUTCModel', internalUTCModel);

			this.firstLoad = false;
			this.allValidations();
			
			if (this.validateUTCDate(internalUTCModel) & this.validateRequiredDate(date) & this.validateRequiredTime(time) & internalUTCModel != this.value) {
				this.internalUTCModel = internalUTCModel;

				if (internalUTCModel != this.value) {
					this.debug && console.log('emit internalUTCModel', internalUTCModel);
					this.$emit('input', internalUTCModel);
				}
			}
		},
		allValidations() {
			this.debug && console.log('allValidations');

			this.clearErrorMessages();
			this.internalDatePickerModel && this.validateMinMaxDate(dateTimeUtils.getLocalFormattedDate(moment(this.internalDatePickerModel, this.ISOdateFormat)));
			this.internalTimePickerModel && this.validateMinMaxTime(this.internalDateFieldModel + ' ' + this.internalTimePickerModel);
			this.internalUTCModel && this.validateUTCDate(this.internalUTCModel);
			this.internalDatePickerModel && this.validateRequiredDate(this.internalDatePickerModel);
			this.internalTimePickerModel && this.validateRequiredTime(this.internalTimePickerModel);
		},
		validateRequiredDate(date) {
			this.debug && console.log('validateRequiredDate', date);

			if (this.required && (date === undefined || date === null || date === '')) {
				this.showRequiredDateMessage();
				this.debug && console.log('validateRequiredDate', false);
				return false;
			}

			return true;
		},
		validateRequiredTime(time) {
			this.debug && console.log('validateRequiredTime', time);

			if (this.showTime && this.required && (time === undefined || time === null || time === '')) {
				this.showRequiredTimeMessage();
				this.debug && console.log('validateRequiredTime', false);
				return false;
			}

			return true;
		},
		validateDate(date) {
			this.debug && console.log('validateDate', date);

			if (!date && !this.required) {
				return true;
			}

			let validation = moment(date, this.ISOdateFormat).isValid();

			if (date && date.length != this.getDateMask.length || date == 'Invalid date' || date == 'Invalid dat' || date == 'Invalid da' || !validation) {
				this.showErrorDateMessage();
				this.debug && console.log('validateDate', false);
				return false;
			}

			return true;
		},
		validateTime(time) {
			this.debug && console.log('validateTime', time);
			if (this.showTime) {
				let timeRegex = new RegExp('^([0-1][0-9]|2[0-3]):([0-5][0-9])$');
				if (this.timesecs) {
					timeRegex = new RegExp('^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$');
				}

				if (time == null) {
					return true;
				}

				if (time.match(timeRegex)) {
					return true;
				}

				this.showErrorTimeMessage();
				this.debug && console.log('validateTime', false);
				return false;
			}
		},
		validateUTCDate(fullDate) {
			this.debug && console.log('validateUTCDate', fullDate);

			let validation = moment(fullDate).isValid();

			if (fullDate == 'Invalid date' || fullDate == 'Invalid dat' || fullDate == 'Invalid da' || !validation) {
				this.debug && console.log('validateUTCDate', false);
				return false;
			}

			return true;
		},
		validateMinMaxDate(date) {
			this.debug && console.log('validateMinMaxDate ' + this.label, date);

			if (!date && !this.required) {
				return true;
			}

			if (this.max && !this.maxDateValidation) {
				this.showErrorMinMaxDateMessage();
				this.debug && console.log('validateMinMaxDate ' + this.label, false);
				return false;
			}

			if (this.min && !this.minDateValidation) {
				this.showErrorMinMaxDateMessage();
				this.debug && console.log('validateMinMaxDate ' + this.label, false);
				return false;
			}

			return true;
		},
		validateMinMaxTime(time) {
			this.debug && console.log('validateMinMaxTime ' + this.label, time);

			if (!time && !this.required) {
				return true;
			}

			if (this.max && !this.maxTimeValidation) {
				this.showErrorMinMaxTimeMessage();
				return false;
			}

			if (this.min && !this.minTimeValidation) {
				this.showErrorMinMaxTimeMessage();
				return false;
			}

			return true;
		},
		clearErrorMessages() {
			this.clearDateErrorMessages();
			this.clearTimeErrorMessages();
		},
		clearDateErrorMessages() {
			this.internalDateError = false;
			this.internalDateErrorMessage = '';
		},
		clearTimeErrorMessages() {
			this.internalTimeError = false;
			this.internalTimeErrorMessage = '';
		},
		showRequiredDateMessage() {
			this.internalDateError = true;
			this.internalDateErrorMessage = this.requiredMessage;
		},
		showRequiredTimeMessage() {
			this.internalTimeError = true;
			this.internalTimeErrorMessage = this.requiredMessage;
		},
		showErrorDateMessage() {
			this.internalDateError = true;
			this.internalDateErrorMessage =
				this.$t('pui9.components.dateField.errorDate') + ',' + this.$t('pui9.components.dateField.correctFormat') + ' ' + this.ISOdateFormat;
		},
		showErrorTimeMessage() {
			this.internalTimeError = true;
			this.internalTimeErrorMessage = this.$t('pui9.components.dateField.errorTime');
		},
		showErrorMinMaxDateMessage() {
			this.internalDateError = true;
			let dateformat = this.ISOdateFormat;

			var min = dateTimeUtils.getLocalFormattedDate(this.min, dateformat);
			var max = dateTimeUtils.getLocalFormattedDate(this.max, dateformat);

			if (this.min && this.max) {
				this.internalDateErrorMessage = this.internalTimeErrorMessage =
					this.$t('pui9.components.dateField.errorRangeDate') + min + ' ' + this.$t('pui9.components.dateField.and') + ' ' + max;
			} else if (this.min && !this.max) {
				this.internalDateErrorMessage = this.$t('pui9.components.dateField.errorMinDate') + min;
			} else {
				this.internalDateErrorMessage = this.$t('pui9.components.dateField.errorMaxDate') + max;
			}
		},
		showErrorMinMaxTimeMessage() {
			this.internalTimeError = true;
			let dateformat = this.ISOdateFormat + ' ' + this.ISOtimeFormat;

			var min = dateTimeUtils.getLocalFormattedDate(this.min, dateformat);
			var max = dateTimeUtils.getLocalFormattedDate(this.max, dateformat);

			if (this.min && this.max) {
				this.internalDateErrorMessage = this.internalTimeErrorMessage =
					this.$t('pui9.components.dateField.errorRangeDate') + min + ' ' + this.$t('pui9.components.dateField.and') + ' ' + max;
			} else if (this.min && !this.max) {
				this.internalTimeErrorMessage = this.$t('pui9.components.dateField.errorMinDate') + min;
			} else {
				this.internalTimeErrorMessage = this.$t('pui9.components.dateField.errorMaxDate') + max;
			}
		},
		getOffset() {
			var offset = dateTimeUtils.getUTCOffset();
			if (offset === 0) {
				return '+00:00';
			}
			var hour = offset / 60;
			if (hour) {
				if (hour && hour.toString().length === 1) {
					if (hour > 0) {
						return '+0' + hour + ':00';
					} else {
						return '-0' + hour + ':00';
					}
				} else if (hour && hour.toString().length === 2) {
					if (hour > 0) {
						return '+' + hour + ':00';
					} else {
						return '-' + hour + ':00';
					}
				}
			}
			return '';
		},
		setTooltip() {
			if (this.tooltip && this.$refs.tooltip) {
				this.$refs.tooltip.clearMessages();

				if (this.showTime) {
					this.$refs.tooltip.pushMessage(
						this.$t('pui9.components.dateField.tooltipFormat') + this.ISOdateFormat + ' ' + this.ISOtimeFormat
					);
				} else {
					this.$refs.tooltip.pushMessage(this.$t('pui9.components.dateField.tooltipFormat') + this.ISOdateFormat);
				}

				if (this.max) {
					let format = '';
					if (this.showTime) {
						format = this.ISOdateFormat + ' ' + this.ISOtimeFormat;
					} else {
						format = this.ISOdateFormat;
					}
					var localMaxDate = dateTimeUtils.getLocalFormattedDate(this.max, format);
					this.$refs.tooltip.pushMessage(this.$t('pui9.components.dateField.tooltipMaxDate') + localMaxDate);
				}

				if (this.min) {
					let format = '';
					if (this.showTime) {
						format = this.ISOdateFormat + ' ' + this.ISOtimeFormat;
					} else {
						format = this.ISOdateFormat;
					}
					var localMinDate = dateTimeUtils.getLocalFormattedDate(this.min, format);
					this.$refs.tooltip.pushMessage(this.$t('pui9.components.dateField.tooltipMinDate') + localMinDate);
				}
			}
		}
	}
};
</script>
