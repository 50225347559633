import * as sanitizeHtml from 'sanitize-html';

export default {
	props: {
		noeditable: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		label: {
			type: String
		},
		toplabel: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			initialValue: '',
			internalModel: '',
			firstLazyLoad: false,
			internalError: false
		};
	},
	computed: {
		allProps() {
			const propsWithoutLabel = { ...this.$props };
			delete propsWithoutLabel.label;
			return { ...this.$attrs, ...propsWithoutLabel };
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		isEdited() {
			if (this.noeditable || this.disabled || this.readonly) {
				return false;
			} else {
				try {
					return this.initialValue.toString() !== this.internalModel.toString();
				} catch (e) {
					return this.initialValue !== this.internalModel;
				}
			}
		},
		userLocaleCode() {
			return (this.$store && this.$store.getters && this.$store.getters.getUserLanguage) || navigator.language;
		},
		getLabel() {
			return this.$props.label;
		},
		getPlaceholder() {
			return this.placeholder;
		},
		hasLabel() {
			if (this.label && this.label.length > 0) {
				return true;
			}
			return false;
		},
		requiredMessage() {
			return this.$t('pui9.error.field_required');
		}
	},
	methods: {
		sanitizePuiFormHtmlText(dirtyText) {
			return sanitizeHtml(dirtyText);
		}
	}
};
