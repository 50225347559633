import Vue from 'vue';
import { SETTINGS_PANEL_ID, VESSEL_PANEL_ID, MOVEMENTS_PANEL_ID, PLANNING_PANEL_ID, REQUESTEDS_PANEL_ID } from '@/lib/variables/panels';
import {
	AIS_VESSEL_GROUP_ID,
	STOP_PREVISION_GROUP_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	FILTER_AIS_VISIT_LAYER_ID,
	FILTER_SERVICE_LAYER_ID,
	FILTER_OTHER_VESSEL_LAYER_ID,
	FILTER_HIGH_FREQUENCY_ID,
	CEP_ZONES_ID
} from '@/components/operations/map/constants/layers';
/** 3rd-party */
import { debounce } from 'lodash';

export default {
	setMixinExecuted(state, value) {
		state.login.mixinExecuted = value;
	},
	setAfterLoginFunction(state, theFunction) {
		state.login.afterLoginFunction = theFunction;
		state.login.mixinExecuted = false;
	},
	setPortAuthorityId(state, portAuthorityId) {
		state.operations.portAuthorityId = portAuthorityId;
	},
	setPortAuthorityCode(state, portAuthorityCode) {
		state.operations.portAuthorityCode = portAuthorityCode;
	},
	setPortlocode(state, portlocode) {
		state.operations.portlocode = portlocode;
	},
	setAfterLogoutFunction(state, theFunction) {
		state.login.afterLogoutFunction = theFunction;
	},
	setUserPortsId(state, userPorts) {
		state.masters.userPortsId = userPorts ? userPorts.map((port) => port.id) : null;
	},

	// Berth planner
	resetBerthPlannerConfiguration(state) {
		state.berthPlanner.configuration = {
			selectedDateModel: {
				startDate: null,
				endDate: null
			},
			currentLocation: {
				currentPort: {},
				currentDock: {},
				currentBerth: {}
			},
			searchText: null,
			vesselDialogModel: null,
			berthBlockDialogModel: null
		};
	},

	// Dynamic panels
	cleanTabs(state) {
		state.mappanelais.activePanel = SETTINGS_PANEL_ID;
		state.mappanelais.dynamicPanels = [];
		state.mappanelperiods.activePanel = REQUESTEDS_PANEL_ID;
		state.mappanelperiods.dynamicPanels = [];
	},
	addDynamicPanelAIS(state, options) {
		const index = state.mappanelais.dynamicPanels.findIndex(({ panelId }) => panelId === options.panelId);
		const exist = index >= 0;

		if (!exist) {
			state.mappanelais.dynamicPanels = [...state.mappanelais.dynamicPanels, options];
		}
	},

	addDynamicPanelPeriods(state, options) {
		const index = state.mappanelperiods.dynamicPanels.findIndex(({ panelId }) => panelId === options.panelId);
		const exist = index >= 0;

		if (!exist) {
			state.mappanelperiods.dynamicPanels = [...state.mappanelperiods.dynamicPanels, options];
		}
	},

	updateDynamicTabData(state, options) {
		const index = state.mappanelperiods.dynamicPanels.findIndex((opt) => opt.panelId === options.panelId);
		const exist = index >= 0;

		if (exist) {
			//state.mappanelperiods.dynamicPanels[index] = options;
			state.mappanelperiods.dynamicPanels.splice(index, 1, options);
		}
	},

	removeDynamicPanelAisByOptionsPanel(state, optionsPanel) {
		// obtener los que se quedan
		const panels = state.mappanelais.dynamicPanels.filter((options) => options.panelId !== optionsPanel.panelId);
		state.mappanelais.dynamicPanels = panels;

		if (state.mappanelais.dynamicPanels.length === 0) {
			state.mappanelais.activePanel = VESSEL_PANEL_ID;
			state.mappanelais.activePanelOptions = null;
		} else {
			const lastElement = state.mappanelais.dynamicPanels[state.mappanelais.dynamicPanels.length - 1];
			state.mappanelais.activePanel = lastElement.panelId;
			state.mappanelais.activePanelOptions = lastElement;
		}
	},

	removeDynamicPanelPeriodsByOptionsPanel(state, optionsPanel) {
		// obtener los que se quedan
		const panels = state.mappanelperiods.dynamicPanels.filter((options) => options.panelId !== optionsPanel.panelId);
		state.mappanelperiods.dynamicPanels = panels;

		if (state.mappanelperiods.dynamicPanels.length === 0) {
			if (optionsPanel.instantPanel) {
				state.mappanelperiods.activePanel = optionsPanel.instantPanel;
			} else {
				state.mappanelperiods.activePanel = REQUESTEDS_PANEL_ID;
			}

			state.mappanelperiods.activePanelOptions = null;
		} else {
			const lastElement = state.mappanelperiods.dynamicPanels[state.mappanelperiods.dynamicPanels.length - 1];
			state.mappanelperiods.activePanel = lastElement.panelId;
			state.mappanelperiods.activePanelOptions = lastElement;
		}

		// Eliminamos de modificaciones el panel cerrado
		if (optionsPanel.instantPanel == PLANNING_PANEL_ID) {
			var index = state.mappanelperiods.stopsFeaturesInstantModifiedPL.findIndex((stop) => stop.id === optionsPanel.data.id);
			const exist = index >= 0;
			if (exist) {
				state.mappanelperiods.stopsFeaturesInstantModifiedPL.splice(index, 1);
			}
			var indexWith = state.mappanelperiods.stopsFeaturesInstantWithModifiedPL.findIndex((stop) => stop.id === optionsPanel.data.id);
			const existWith = indexWith >= 0;
			if (existWith) {
				const {
					$app: { $puiEvents }
				} = this;
				state.mappanelperiods.processVesselsRunning = true;
				if (state.mappanelperiods.stopsFeaturesInstantModifiedPL.length > 0) {
					// Replazamos por el sin modificar
					var stop = state.mappanelperiods.stopsFeaturesInstantInitially.find((stop) => stop.id === optionsPanel.data.id);
					state.mappanelperiods.stopsFeaturesInstantWithModifiedPL.splice(indexWith, 1, stop);
					$puiEvents.$emit('stopfeaturecreator:updated', state.mappanelperiods.stopsFeaturesInstantWithModifiedPL, true);
				} else {
					state.mappanelperiods.stopsFeaturesInstantWithModifiedPL = [];
					// NO lanzamos stopfeaturecreator:updated porque onActivePanelChange se activara al no quedar tarjetas abiertas
				}
			}
		} else if (REQUESTEDS_PANEL_ID) {
			var index = state.mappanelperiods.stopsFeaturesInstantModifiedAU.findIndex((stop) => stop.id === optionsPanel.data.id);
			const exist = index >= 0;
			if (exist) {
				state.mappanelperiods.stopsFeaturesInstantModifiedAU.splice(index, 1);
			}
			var indexWith = state.mappanelperiods.stopsFeaturesInstantWithModifiedAU.findIndex((stop) => stop.id === optionsPanel.data.id);
			const existWith = indexWith >= 0;
			if (existWith) {
				const {
					$app: { $puiEvents }
				} = this;
				state.mappanelperiods.processVesselsRunning = true;
				if (state.mappanelperiods.stopsFeaturesInstantModifiedAU.length > 0) {
					// Replazamos por el sin modificar
					var stop = state.mappanelperiods.stopsFeaturesInstantInitially.find((stop) => stop.id === optionsPanel.data.id);
					console.log(stop);
					state.mappanelperiods.stopsFeaturesInstantWithModifiedAU.splice(indexWith, 1, stop);
					$puiEvents.$emit('stopfeaturecreator:updated', state.mappanelperiods.stopsFeaturesInstantWithModifiedAU, true);
				} else {
					state.mappanelperiods.stopsFeaturesInstantWithModifiedAU = [];
					// NO lanzamos stopfeaturecreator:updated porque onActivePanelChange se activara al no quedar tarjetas abiertas
				}
			}
		}
	},

	removeDynamicPanelPeriodsByPanelId(state, panelId) {
		// Obtener los que se quedan
		const panels = state.mappanelperiods.dynamicPanels.filter((options) => options.panelId !== panelId);
		state.mappanelperiods.dynamicPanels = panels;
	},

	setDynamicPanelPeriods(state, panels) {
		state.mappanelperiods.dynamicPanels = panels;
	},

	setActivePanelAIS(state, options) {
		state.mappanelais.activePanel = options.panelId;
		state.mappanelais.activePanelOptions = options.identifier ? options : null;
	},

	updateActivePanelAIS(state, options) {
		state.mappanelais.activePanel = options.panelId;
		state.mappanelais.activePanelOptions = options.identifier ? options : null;
	},

	setActivePanelPeriods(state, options) {
		state.mappanelperiods.activePanel = options.panelId;
		state.mappanelperiods.activePanelOptions = options.identifier ? options : null;
	},

	setPanelParentActive(state, options) {
		state.mappanelperiods.panelParentActive = options.panelId;
	},

	// Map Config
	setUserQuaysId(state, userQuays) {
		state.mapconfig.userQuaysId = userQuays ? userQuays.map((quay) => quay.id) : null;
	},
	setStopsStatusValues: (state, stopsStatusValues) => {
		state.stopsStatusValues = stopsStatusValues;
	},
	setVisitsStatusValues: (state, visitsStatusValues) => {
		state.visitsStatusValues = visitsStatusValues;
	},
	setWeatherKey: (state, weatherKey) => {
		state.weatherKey = weatherKey;
	},
	setWeatherForecastInBP: (state, weatherForecastInBP) => {
		state.berthPlanner.WEATHER_FORECAST_IN_BP = weatherForecastInBP;
	},
	setCheckActiveAisEvents: (state, checkActiveAisEvents) => {
		state.checkActiveAisEvents = checkActiveAisEvents;
	},
	addBerths(state, berths) {
		state.mapconfig.berths = state.mapconfig.berths.concat(berths);
	},

	addBollards(state, bollards) {
		Object.assign(state.mapconfig.bollards, bollards);
	},

	setMapPortsExtents(state, value) {
		state.mapconfig.mapPortsExtents = value;
	},

	setMapAutorityExtent(state, value) {
		state.mapconfig.mapAutorityExtent = value;
	},

	setPortAutorityHaveStatusPl(state, value) {
		state.mapconfig.portAutorityHaveStatusPl = value;
	},

	setStopsTypeValues(state, value) {
		state.mapconfig.stopsTypeValues = value;
	},
	// Map AIS
	setAisPortCallNumbers(state, value) {
		state.mappanelais.aisPortCallNumbers = value;
	},

	setMapAisFitPortExtent(state, value) {
		state.mappanelais.mapFitPortExtent = value;
	},

	setSearchingText(state, value) {
		state.mappanelais.searchingtext = value;
	},

	setMapAisLegendExpanded(state, expanded) {
		state.mappanelais.map.legendExpanded = expanded;
	},

	setMapAisStyleVisualization(state, value) {
		state.mappanelais.mapStyleVisualization = value;
	},

	setMapAisPortVisualization(state, value) {
		state.mappanelais.mapPortVisualization = value;
	},

	setMapAisLoaded(state, value) {
		state.mappanelais.mapAisIsLoaded = value;
	},

	setMapZoomAIS(state, zoom) {
		state.mappanelais.map.zoom = zoom;
	},

	setMapBbox(state, bbox) {
		setDelayedBbox(state, bbox);
	},

	setStopsFeaturesAccepted(state, features) {
		state.mappanelais.stopsFeaturesAccepted = features;
	},

	setStopsFeaturesPlanned(state, features) {
		state.mappanelais.stopsFeaturesPlanned = features;
	},

	setStopsFeaturesAuthorized(state, features) {
		state.mappanelais.stopsFeaturesAuthorized = features;
	},

	setStopsFeaturesConfirmed(state, features) {
		state.mappanelais.stopsFeaturesConfirmed = features;
	},

	setStopsFeaturesInitiated(state, features) {
		state.mappanelais.stopsFeaturesInitiated = features;
	},

	setSubLayerGroupActive(state, { key, subLayer, active }) {
		Vue.set(state.mappanelais.layers, key, {
			...state.mappanelais.layers[key],
			[subLayer]: active
		});
	},

	clearAllLayersAISList(state) {
		const keyToBoolean = (bool) => (object, key) => ({ ...object, [key]: bool });
		const trueObjectPrevision = ['group'].reduce(keyToBoolean(true), {});
		const falseObjectPrevision = [
			'opened',
			'active',
			'filtered',
			STOP_ACCEPTED_LAYER_ID,
			STOP_PLANNED_LAYER_ID,
			STOP_AUTHORIZED_LAYER_ID,
			STOP_CONFIRMED_LAYER_ID,
			STOP_INITIATED_LAYER_ID
		].reduce(keyToBoolean(false), {});

		const trueObjectAis = [
			'active',
			'group',
			FILTER_AIS_VISIT_LAYER_ID,
			FILTER_SERVICE_LAYER_ID,
			FILTER_OTHER_VESSEL_LAYER_ID,
			FILTER_HIGH_FREQUENCY_ID
		].reduce(keyToBoolean(true), {});
		const falseObjectAis = ['opened', 'filtered'].reduce(keyToBoolean(false), {});

		Vue.set(state.mappanelais.layers, AIS_VESSEL_GROUP_ID, {
			...state.mappanelais.layers[AIS_VESSEL_GROUP_ID],
			...falseObjectPrevision,
			...trueObjectAis
		});

		Vue.set(state.mappanelais.layers, STOP_PREVISION_GROUP_ID, {
			...state.mappanelais.layers[STOP_PREVISION_GROUP_ID],
			...falseObjectAis,
			...trueObjectPrevision
		});

		const falseObjectCep = ['opened', 'active', 'filtered'].reduce(keyToBoolean(false), {});
		Vue.set(state.mappanelais.layers, CEP_ZONES_ID, {
			...state.mappanelais.layers[CEP_ZONES_ID],
			...falseObjectCep
		});
	},

	// Map periods
	setProcessVesselsRunning(state, value) {
		state.mappanelperiods.processVesselsRunning = value;
	},

	setMapPeriodsFitPortExtent(state, value) {
		state.mappanelperiods.mapFitPortExtent = value;
	},

	setSearchingPastText(state, value) {
		state.mappanelperiods.searchingtextpastpanel = value;
	},

	setSearchingFutureText(state, value) {
		state.mappanelperiods.searchingtextfuturepanel = value;
	},

	setSearchingRequestedText(state, value) {
		state.mappanelperiods.searchingtextrequestedspanel = value;
	},

	setSearchingPlanningText(state, value) {
		state.mappanelperiods.searchingtextplanningpanel = value;
	},

	setMapPeriodsLoaded(state, value) {
		state.mappanelperiods.mapPeriodsIsLoaded = value;
	},

	setMapPeriodsStyleVisualization(state, value) {
		state.mappanelperiods.mapStyleVisualization = value;
	},

	setMapPeriodsPortVisualization(state, value) {
		state.mappanelperiods.mapPortVisualization = value;
	},

	// Stops iniciales
	setStopsFeaturesInstantInitially(state, features) {
		state.mappanelperiods.stopsFeaturesInstantInitially = features;
	},

	// Past and future
	setStopsFeaturesInstant(state, features) {
		state.mappanelperiods.stopsFeaturesInstant = features;
	},

	// Planning
	setStopsFeaturesInstantPL(state, features) {
		state.mappanelperiods.stopsFeaturesInstantPL = features;
	},

	setStopsFeaturesInstantModifiedPL(state, features) {
		state.mappanelperiods.stopsFeaturesInstantModifiedPL = features;
	},

	setStopsFeaturesInstantWithModifiedPL(state, features) {
		state.mappanelperiods.stopsFeaturesInstantWithModifiedPL = features;
	},

	setStopsFeaturesInstantRemovedPL(state, stops) {
		state.mappanelperiods.stopsFeaturesInstantRemovedPL = stops;
	},

	addStopsFeaturesInstantRemovedPL(state, stop) {
		state.mappanelperiods.stopsFeaturesInstantRemovedPL = [...state.mappanelperiods.stopsFeaturesInstantRemovedPL, stop];
	},

	removeStopsFeaturesInstantRemovedPL(state, stop) {
		var index = state.mappanelperiods.stopsFeaturesInstantRemovedPL.findIndex((st) => st.id === stop.id);
		const exist = index >= 0;
		if (exist) {
			state.mappanelperiods.stopsFeaturesInstantRemovedPL.splice(index, 1);
		}
	},

	cleanStopModificationsPL(state, stopid) {
		// Obtenemos el stop original
		const stopOriginal = state.mappanelperiods.stopsFeaturesInstantPL.find((st) => st.id === stopid);

		// Eliminamos el stop de stopsFeaturesInstantModifiedPL
		var indexInstantModifiedPL = state.mappanelperiods.stopsFeaturesInstantModifiedPL.findIndex((st) => st.id === stopid);
		const existInstantModifiedPL = indexInstantModifiedPL >= 0;
		if (existInstantModifiedPL) {
			state.mappanelperiods.stopsFeaturesInstantModifiedPL.splice(indexInstantModifiedPL, 1);
		}

		// Remplazamos el stop de stopsFeaturesInstantWithModifiedPL por el original
		var indexInstantWithModifiedPL = state.mappanelperiods.stopsFeaturesInstantWithModifiedPL.findIndex((st) => st.id === stopid);
		const existInstantWithModifiedPL = indexInstantWithModifiedPL >= 0;
		if (existInstantWithModifiedPL) {
			state.mappanelperiods.stopsFeaturesInstantWithModifiedPL.splice(indexInstantWithModifiedPL, 1, stopOriginal);
		}
	},

	// Authorization
	setStopsFeaturesInstantAU(state, features) {
		state.mappanelperiods.stopsFeaturesInstantAU = features;
	},

	setStopsFeaturesInstantModifiedAU(state, features) {
		state.mappanelperiods.stopsFeaturesInstantModifiedAU = features;
	},

	setStopsFeaturesInstantWithModifiedAU(state, features) {
		state.mappanelperiods.stopsFeaturesInstantWithModifiedAU = features;
	},

	setStopsFeaturesInstantRemovedAU(state, stops) {
		state.mappanelperiods.stopsFeaturesInstantRemovedAU = stops;
	},

	addStopsFeaturesInstantRemovedAU(state, stop) {
		state.mappanelperiods.stopsFeaturesInstantRemovedAU = [...state.mappanelperiods.stopsFeaturesInstantRemovedAU, stop];
	},

	removeStopsFeaturesInstantRemovedAU(state, stop) {
		var index = state.mappanelperiods.stopsFeaturesInstantRemovedAU.findIndex((st) => st.id === stop.id);
		const exist = index >= 0;
		if (exist) {
			state.mappanelperiods.stopsFeaturesInstantRemovedAU.splice(index, 1);
		}
	},

	setMapZoomPeriods(state, zoom) {
		state.mappanelperiods.map.zoom = zoom;
	},

	// Instant dates
	setFecIniPastpanel(state, date) {
		state.mappanelperiods.fecIniPastpanel = date;
	},
	setFecEndPastpanel(state, date) {
		state.mappanelperiods.fecEndPastpanel = date;
	},
	setFecIniFuturepanel(state, date) {
		state.mappanelperiods.fecIniFuturepanel = date;
	},
	setFecEndFuturepanel(state, date) {
		state.mappanelperiods.fecEndFuturepanel = date;
	},
	setFecIniRequestedspanel(state, date) {
		state.mappanelperiods.fecIniRequestedspanel = date;
	},
	setFecEndRequestedspanel(state, date) {
		state.mappanelperiods.fecEndRequestedspanel = date;
	},
	setFecIniPlanningpanel(state, date) {
		state.mappanelperiods.fecIniPlanningpanel = date;
	},
	setFecEndPlanningpanel(state, date) {
		state.mappanelperiods.fecEndPlanningpanel = date;
	},

	setMapBbox(state, bbox) {
		setDelayedBboxPerdiods(state, bbox);
	},

	setShipSelectedId(state, shipid) {
		state.operations.shipSelectedId = shipid;
	},

	// MAP Visits
	setMapZoomVisits(state, zoom) {
		state.mapvisits.map.zoom = zoom;
	}
};

const setDelayedBbox = debounce((state, bbox) => {
	normalizedBoundingBox(state, bbox);
}, 500);

const normalizedBoundingBox = (state, bbox) => {
	const [xmin, ymin, xmax, ymax] = bbox;
	state.mappanelais.map.bbox = [ymin < -90 ? -90 : ymin, xmax > 180 ? 180 : xmax, ymax > 90 ? 90 : ymax, xmin < -180 ? -180 : xmin];
};

const setDelayedBboxPerdiods = debounce((state, bbox) => {
	normalizedBoundingBoxPerdiods(state, bbox);
}, 500);

const normalizedBoundingBoxPerdiods = (state, bbox) => {
	const [xmin, ymin, xmax, ymax] = bbox;
	state.mappanelperiods.map.bbox = [ymin < -90 ? -90 : ymin, xmax > 180 ? 180 : xmax, ymax > 90 ? 90 : ymax, xmin < -180 ? -180 : xmin];
};
